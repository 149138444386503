.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Lighter background color */
    margin-top: -12.5vh;
}

.login-container {
    text-align: center;
    padding: 90px; /* Adjust padding to match the other design */
    background-color: transparent;
    border-radius: 5px; /* Reduced border-radius */
}

.login-logo {
    width: 60px; /* Smaller logo */
    margin-bottom: 10px;
}

h2 {
    font-size: 22px; /* Slightly smaller font size */
    margin-bottom: 10px;
    color: #333; /* Darker text color for contrast */
}

.login-input {
    width: 100%;
    padding: 1vh;
    margin-top: 2vh;
    margin-bottom: -2vh;
    border: 2px solid #aaa;
    background-color: transparent;
    border-radius: 3px;
    font-size: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 45px;
    min-width: 35vh;
    position: relative;
    box-sizing: border-box;
    transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.login-input:focus {
    border-color: #b71818; /* Change the border color when focused */
    outline: none; /* Remove default outline */
}


.login-button {
    font-size: 16px;
    background-color: transparent;
    color: #333;
    border: none; /* Remove border */
    border-radius: 0 3px 3px 0; /* Match the right end of the input box */
    cursor: pointer;
    position: relative;
    left: 30vh; /* Ensure it's inside the input box */
    bottom: 0.25vh;
    transform: translateY(-90%);
    padding: 0 15px; /* Adjust padding */
    height: calc(2%); /* Slightly smaller than input height to avoid overlap */
    display: flex;
    justify-content: center;
    align-items: center;
}


.login-button:hover {
    color: #b71818;
}

.error-container {
    height: 20px; /* Fixed height for the error message container */
    margin-top: -1vh; /* Space between input and error message */
}

.error-message {
    color: #b71818; /* Set the color of the error message */
    font-size: 14px; /* Set the font size */
    margin-right: 4vh; /* Remove any margin that could affect layout */
    line-height: 20px; /* Match the height of the container */
    opacity: 1; /* Default is fully visible */
}

.fade-out {
    opacity: 0; /* Fade out the message */
    transition: opacity 0.5s ease-out; /* Smooth fading transition for fading out only */
}

@media only screen and (max-width: 430px) {
    .login-page {
        margin-top: -9.5vh;
      }
}