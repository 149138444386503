/* Footer.css */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .footer {
    background-color: #171717; /* Same black color as the pillar section */
    padding: 20px 0;
    text-align: center;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .footerContainer {
    width: 100%;
  }
  
  .socialIcons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  
  .socialIcons a {
    text-decoration: none;
    padding: 10px;
    background-color: white;
    margin: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .socialIcons a i {
    font-size: 2em;
    color: black;
    opacity: .9;
  }
  
  .socialIcons a:hover {
    background-color: black;
    transition: 0.5s;
  }
  
  .socialIcons a:hover i {
    color: white;
    transition: 0.5s;
  }
  
  .footerBottom {
    margin-top: 5vh;
  }
  

  