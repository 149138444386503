.members-page-section {
    position: relative;
    margin-top: -12.5vh;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-size: cover; /* Cover the entire section */
    background-attachment: fixed; /* Fix the background for parallax effect */
    background-position: top; /* Center the image */
    display: flex;
    align-items: center;
    justify-content: center;
    color: whitesmoke; /* Text color */
    text-align: center;
    z-index: 1; /* Ensure it's above the background */
}

.members-page-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(151, 148, 148, 0.4); /* Dark overlay with opacity */
    z-index: 1;
}

.members-page-text {
    position: relative;
    z-index: 2; /* Ensure the text is above the overlay */
    padding: 2rem;
    border-radius: 1rem;
}

.members-page-text h1 {
    font-size: 5vh; /* Use vh for responsiveness */
    margin-bottom: 1rem;
}

.members-page-text p {
    font-size: 2vh; /* Use vh for responsiveness */
    margin-bottom: 0;
}

.members-page-scroll-arrow {
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    cursor: pointer;
    font-size: 2vh;
    color: #f0f0f0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2; /* Ensure it's above other elements */
}

.members-page-scroll-arrow:hover .members-page-arrow {
    opacity: 0;
}

.members-page-scroll-arrow:hover .members-page-scroll-text {
    opacity: 1;
}

.members-page-arrow {
    display: inline-block;
    width: 3vh; /* Adjust width for the wide V shape */
    height: 3vh; /* Adjust height for the wide V shape */
    border-left: 4px solid #f0f0f0; /* Adjust border thickness for the wide V shape */
    border-bottom: 4px solid #f0f0f0; /* Adjust border thickness for the wide V shape */
    transform: rotate(-45deg);
    transition: opacity 0.3s ease-in-out;
}

.members-page-scroll-text {
    display: block;
    opacity: 0;
    font-size: 1.5vh;
    color: #f0f0f0;
    transition: opacity 0.3s ease-in-out;
    margin-top: -1vh;
}

.members-page-background {
    width: 100%;
    background-color: #f0f0f0;
}

.members-page-content {
    text-align: center;
    background-color: #f0f0f0; /* Light background color */
    color: #333; /* Dark text color */
    margin: 0 auto; /* Center the section horizontally */
    max-width: 160vh; /* Set a maximum width */
    z-index: 1;
    padding: 5vh 2vh; /* Use vh for padding */
}

.subsection {
    margin: 10vh 0; /* Use vh for spacing around subsections */
}

.subsection-header {
    font-size: 4vh; /* Use vh for responsiveness */
    margin-bottom: 2vh;
    color: #b71818; /* Red color for the header */
}

.separator {
    height: 0.2vh; /* Use vh for height */
    background-color: #ddd; /* Light gray color for the separator */
    margin: 2vh 0; /* Use vh for spacing */
}

.member-card {
    display: inline-block;
    width: 25vh; /* Use vh for width of the card */
    margin: 0; /* Use vh for spacing between cards */
    text-align: center;
    vertical-align: top; /* Ensure alignment with images */
    height: 42vh; /* Set a fixed height for the card */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.member-card img {
    width: 100%;
    height: auto;
    border-radius: 0%; /* Rectangular headshots */
    margin-bottom: 1vh; /* Use vh for margin */
}

.member-card .name {
    font-size: 2.2vh; /* Use vh for responsiveness */
    font-weight: bold;
    white-space: nowrap; /* Ensure name stays on one line */
    overflow: hidden;
    line-height: 1.5em; /* Align vertically */
    text-align: center; /* Center text */
    display: block;
}

.member-card .subtitle,
.member-card .email {
    font-size: 1.7vh; /* Use vh for responsiveness */
    color: #777; /* Gray color for the subtitle */
    white-space: normal; /* Allow wrapping */
    overflow: hidden;
    line-height: 1.5em; /* Align vertically */
    text-align: center; /* Center text */
    display: block;
    /*height: 4vh; /* Set a fixed height for the subtitle */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
}
  
.member-card {
    opacity: 0; /* Ensure initial state is hidden */
}

.members-list {
    display: grid;
    grid-template-columns: repeat(3, auto); /* Use auto to fit content */
    grid-column-gap: 17vh; /* Reduce the horizontal gap between columns */
    grid-row-gap: 2vh; /* Keep the vertical gap */
    justify-content: center; /* Center the grid */
    align-items: center;
    padding: 3vh 0;
}

/* Mobile screens (up to 430px, e.g., iPhone 14 Pro Max) */
@media only screen and (max-width: 430px) {
    /* First Section Styling for iPhone View */
    .members-page-section {
      position: relative;
      width: 100%;
      height: 100vh; /* Ensure it covers the full height of the viewport */
      overflow: hidden;
      margin-top: -9.5vh;
      padding: 0;
      z-index: 1; /* Ensure it stays above the background */
      padding-top: 10vh; /* Adjust this to fit to the screen properly */
      background-attachment: scroll;
    }
  
    .members-page-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(151, 148, 148, 0.4); /* Dark overlay with opacity */
      z-index: 1;
    }
  
    .members-page-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; /* Ensure the text is above the overlay */
      padding: 2rem;
      border-radius: 1rem;
      text-align: center; /* Center align text */
      color: whitesmoke; /* Text color */
    }
  
    .members-page-text h1 {
      font-size: 4vh; /* Adjust font size for responsiveness */
      margin-bottom: 2vh;
    }
  
    .members-page-text p {
      font-size: 2vh; /* Adjust font size */
      margin-bottom: 2vh;
    }
  
    /* Eboard Members in One Column */
    .members-list {
      display: grid;
      grid-template-columns: 1fr; /* One column layout */
      grid-row-gap: 2vh; /* Vertical gap between members */
      justify-content: center;
      padding: 5vh 2vh; /* Adjust padding for spacing */
    }
  
    .member-card {
      width: 70vw; /* Adjust width of each member card */
      height: auto; /* Allow height to adjust based on content */
      margin: 0 auto 2vh auto; /* Center align and add margin at the bottom */
      box-sizing: border-box; /* Include padding and border in element's total width and height */
    }
  
    .member-card img {
      width: 100%;
      height: auto;
      border-radius: 1vh; /* Add slight rounding to images */
      margin-bottom: 1vh; /* Adjust margin below images */
    }
  
    .member-card .name {
      font-size: 2.2vh; /* Adjust font size */
      font-weight: bold;
      white-space: normal; /* Allow text to wrap */
      text-align: center; /* Center align text */
    }
  
    .member-card .subtitle,
    .member-card .email {
      font-size: 1.7vh; /* Adjust font size */
      color: #777; /* Gray color for subtitle and email */
      text-align: center; /* Center align text */
      line-height: 1.5em;
    }
  }
  
