/* General styling for all pages */
.nav {
  display: flex;
  align-items: center;
  position: relative; /* Use relative positioning */
  padding: 0vh; /* Adjust padding as needed */
  background-color: transparent;
  width: 99%;
  z-index: 1000; /* Ensure it stays above other elements */
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0; /* Remove any margin */
}

.logo {
  margin-left: 2.5vh;
  height: 12vh; /* Adjust the height */
  width: auto;
}

/* General PC View Styling */
.nav-links li a {
  font-size: 1.6vh; /* Adjust font size */
  padding: 1vh; /* Adjust padding */
  line-height: 1.2; /* Adjust line height */
  white-space: normal; /* Allow text wrapping */
  display: inline-block; /* Ensure proper wrapping and stacking */
  vertical-align: middle; /* Align text vertically in the middle */
  color: whitesmoke; /* Default color for all pages */
  position: relative;
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.nav-links li a::after {
  content: '';
  display: block;
  width: 0;
  height: 0.1vh;
  background: transparent;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.nav-links li a:hover {
  color: #b71818; /* Change text color to red on hover */
}

.nav-links li a:hover::after {
  width: 100%; /* Expand underline */
  background: #b71818; /* Change underline color to red */
}

/* Specific styling for the login page */
.nav-login .nav-links li a {
  color: black; /* Default color on login page */
}

.nav-login .nav-links li a:hover {
  color: #b71818; /* Keep text color black on hover */
}

.nav-login .nav-links li a:hover::after {
  width: 100%; /* Expand underline */
  background: #b71818; /* Change underline color to red */
}


/* Mobile screens (up to 430px, e.g., iPhone 14 Pro Max) */
@media only screen and (max-width: 430px) {
  .nav {
    position: relative;
    top: 0;
    left: 0;
    width: 100%; /* Ensure it uses the full width of the screen */
    padding: 1vh 2vh; /* Adjust padding */
    background-color: transparent;
    z-index: 1000; /* Keep it above other elements initially */
  }

  .logo {
    height: 7vh; /* Reduce the logo size */
    margin-left: 0.3vh; /* Align it closer to the edge */
  }

  .nav-links {
    gap: 1.1vh; /* Reduce the gap between nav items */
  }

  .nav-links li a {
    font-size: 1.1vh; /* Font size for mobile */
    padding: 1.0vh; /* Adjust padding */
    line-height: 1.1; /* Adjust line height to stack lines closer together */
    white-space: normal; /* Allow text wrapping */
    display: inline-block; /* Ensure proper wrapping and stacking */
    vertical-align: middle; /* Align text vertically in the middle */
    color: whitesmoke; /* Default color for all pages */
  }

  .nav-links li a::after {
    height: 0.1vh; /* Reduce the height of the underline */
  }

  .nav-links li a:hover::after {
    height: 0.1vh; /* Ensure hover effect stays consistent */
  }

  /* Specific styling for the login page */
  .nav-login .nav-links li a {
    color: black; /* Change text color to black on login page */
  }

  .nav-login .nav-links li a:hover::after {
    background: black; /* Set the underline to black as well */
  }
}
