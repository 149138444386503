.about-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-size: cover; /* Cover the entire section */
    background-attachment: fixed; /* Fix the background for parallax effect */
    background-position: top; /* Center the image */
    display: flex;
    align-items: center;
    justify-content: center;
    color: whitesmoke; /* Text color */
    text-align: center;
    margin-top: -12.5vh; /* Display the picture right at the top */
    z-index: 1; /* Ensure it's above the background */
    padding-top: 15vh; /* Ensure content is visible within the section */
    padding-bottom: 15vh; /* Ensure content is visible within the section */
}

.about-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(151, 148, 148, 0.4); /* Dark overlay with opacity */
    z-index: 1;
}

.about-text {
    position: relative;
    z-index: 2; /* Ensure the text is above the overlay */
    padding: 2rem;
    border-radius: 1rem;
}

.about-text h1 {
    font-size: 5vh;
    margin-bottom: 1rem;
}

.about-text p {
    font-size: 2vh;
    margin-bottom: 0;
}

.about-container {
    width: 100%;
    background-color: #f0f0f0;
}

.about-us-section,
.mission-vision-section,
.about-pillars-section {
    min-height: 100vh; /* Set the minimum height to the full viewport height */
    padding: 15vh 10vh; /* Keep padding consistent */
    text-align: center;
    background-color: #f0f0f0; /* Background color */
    color: #333; /* Text color */
    margin: 0 auto; /* Center the section */
    width: 80%; /* Ensure full width */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.about-pillars-section h2,
.about-us-section h2,
.mission-vision-section h2 {
    font-size: 6vh; /* Consistent font size */
    margin-bottom: 4vh; /* Consistent margin */
    color: #b71818; /* Red color for the headers */
}

.about-pillars-section p,
.about-us-section p,
.mission-vision-section p {
    font-size: 3vh; /* Consistent font size for paragraphs */
    line-height: 1.6;
    margin-bottom: 7vh; /* Consistent margin */
}


.statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create 4 equal columns */
    gap: 3vh; /* Add space between the columns */
    justify-items: center; /* Center items horizontally */
}

.stat {
    text-align: center;
}

.stat h3 {
    font-size: 5.9vh;
    color: #b71818; /* Red color for the numbers */
    margin-bottom: 1vh;
}

.stat p {
    font-size: 3vh;
    color: #b71818; /* Red color for the labels */
}

.scroll-arrow {
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    cursor: pointer;
    font-size: 2vh;
    color: #f0f0f0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2; /* Ensure it's above other elements */
}

.scroll-arrow:hover .arrow {
    opacity: 0;
}

.scroll-arrow:hover .scroll-text {
    opacity: 1;
}

.arrow {
    display: inline-block;
    width: 3vh; /* Adjust width for the wide V shape */
    height: 3vh; /* Adjust height for the wide V shape */
    border-left: 4px solid #f0f0f0; /* Adjust border thickness for the wide V shape */
    border-bottom: 4px solid #f0f0f0; /* Adjust border thickness for the wide V shape */
    transform: rotate(-45deg);
    transition: opacity 0.3s ease-in-out;
}

.scroll-text {
    display: block;
    opacity: 0;
    font-size: 1.5vh;
    color: #f0f0f0;
    transition: opacity 0.3s ease-in-out;
    margin-top: -1vh;
}

.about-section.second-image {
    background-position: center bottom; /* Adjust to crop the lower part of the image */
    background-size: cover; /* Make sure the image still covers the section */
}

.about-section.third-image {
    background-position: center bottom; /* Adjust to crop the lower part of the image */
    background-size: cover; /* Make sure the image still covers the section */
}

.about-section.fourth-image {
    background-position: 50% 50%; /* Move the image down by adjusting the vertical position */
    background-size: cover; /* Make sure the image still covers the section */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
}

.members-section {
    text-align: center;
    padding: 15vh 10vh;
    background-color: #f0f0f0;
    color: #333;
    margin: 0 auto;
    max-width: 170vh;
    z-index: 1;
    margin-bottom: 15vh;
}

.members-section h2 {
    font-size: 5vh;
    margin-bottom: 4vh;
    color: #b71818;
}

.members-section ul {
    list-style: none; /* Remove bullet points */
    padding: 0;
    font-size: 3vh; /* Increase font size */
    line-height: 4.9vh; /* Adjust line height for spacing */
}

.members-section li {
    font-size: 3.5vh; /* Increase font size */
    margin-bottom: 20vh;
    font-weight: bold; /* Make text bold */
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease-out; /* Smooth transition for fade-in effect */
    margin-bottom: 3vh;
}

.sponsors-partners-section {
    text-align: center;
    padding: 15vh 10vh;
    background-color: #f0f0f0;
    color: #333;
    margin: 0 auto;
    max-width: 160vh;
    z-index: 1;
}

.sponsors-partners-section h2 {
    font-size: 6vh;
    margin-bottom: 4vh;
    color: #b71818;
}

.sponsors-logos {
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Use grid layout */
    gap: 4vh; /* Adjust gap as needed */
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.partners-logos {
    margin-top: 10vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Use grid layout */
    gap: 4vh; /* Adjust gap as needed */
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.sponsors-partners-logo {
    width: 20vh; /* Adjust width as needed */
    height: auto;
}

.custom-size {
    width: 11vh; /* Custom size for Summit Financial logo */
    height: auto;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
}

.sponsor-logo {
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease-out; /* Smooth transition for fade-in effect */
}

@media only screen and (max-width: 430px) {
    .about-section {
        position: relative;
        width: 100%;
        height: 100vh; /* Ensure it covers the full height of the viewport */
        overflow: hidden;
        margin: 0;
        padding: 0;
        z-index: 1; /* Ensure it stays above the background */
        margin-top: -9.5vh; /* Adjust this to match the video section */
        padding-top: 10vh; /* Add padding equal to the margin-top for alignment */
        background-attachment: scroll; /* Ensure the background scrolls with the content */
      }
  
    .about-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(151, 148, 148, 0.4); /* Dark overlay with opacity */
      z-index: 1;
    }
  
    .about-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; /* Ensure the text is above the overlay */
      padding: 2rem;
      border-radius: 1rem;
      text-align: center; /* Center align text */
      color: whitesmoke; /* Text color */
    }
  
    .about-text h1 {
      font-size: 4vh;
      margin-bottom: 2vh;
    }
  
    .about-text p {
      font-size: 2vh;
      margin-bottom: 2vh;
    }

    .about-us-section {
        padding: 10vh 5vh;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
      }
    
      .about-us-section h2 {
        font-size: 4vh; /* Adjust font size for heading */
        margin-bottom: 3vh;
      }
      .stat h3 {
        font-size: 3.9vh;
        color: #b71818; /* Red color for the numbers */
        margin-bottom: 1vh;
    }
    
      .about-us-section p {
        font-size: 2.2vh;
        line-height: 1.4;
        margin-bottom: 3vh;
      }

      .statistics {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
        gap: 2vh; /* Space between the items */
        justify-items: center; /* Center items horizontally */
        margin-top: 3vh;
    }

    .stat {
        text-align: center;
        padding: 2vh 1vh; /* Adjust padding for better spacing */
    }

    .stat h3 {
        font-size: 3.9vh; /* Adjust font size for mobile */
        color: #b71818; /* Red color for the numbers */
        margin-bottom: 1vh;
    }

    .stat p {
        font-size: 2.5vh; /* Adjust font size for mobile */
        color: #b71818; /* Red color for the labels */
    }

    .mission-vision-section {
        padding: 10vh 5vh;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
      }
    
      .mission-vision-section h2 {
        font-size: 4vh;
        margin-bottom: 3vh;
      }
    
      .mission-vision-section p {
        font-size: 2.5vh;
        line-height: 1.4;
        margin-bottom: 3vh;
      }

      .members-section {
        padding: 10vh 5vh;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10vh; /* Adjust margin for spacing */
      }
    
      .members-section h2 {
        font-size: 4vh;
        margin-bottom: 3vh;
      }
    
      .members-section ul {
        padding: 0;
        font-size: 2.5vh;
        line-height: 4.9vh;
      }
    
      .members-section li {
        font-size: 2.8vh;
        margin-bottom: 3vh;
        font-weight: bold;
      }

      .sponsors-partners-section {
        padding: 10vh 5vh;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
      }
    
      .sponsors-partners-section h2 {
        font-size: 4vh;
        margin-bottom: 3vh;
      }
    
      .sponsors-logos {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for sponsors */
        gap: 4vh;
      }
    
      .partners-logos {
        grid-template-columns: 1fr; /* 1 column for partners */
        gap: 4vh;
      }
    
      .sponsors-partners-logo,
      .custom-size {
        width: 15vh; /* Adjust logo size */
      }
  }