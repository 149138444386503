.join-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.join-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-size: cover; /* Cover the entire section */
    background-attachment: fixed; /* Fix the background for parallax effect */
    background-position: 50% 50%;
    margin-top: -12.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: whitesmoke; /* Text color */
    text-align: center;
    z-index: 1; /* Ensure it's above the background */
}

.join-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(151, 148, 148, 0.4); /* Dark overlay with opacity */
    z-index: 1;
}

.join-text {
    position: relative;
    z-index: 2; /* Ensure the text is above the overlay */
    padding: 2rem;
    border-radius: 1rem;
}

.join-text h1 {
    font-size: 5vh;
    margin-bottom: 1rem;
}

.join-text p {
    font-size: 2vh;
    margin-bottom: 0;
}

.scroll-arrow {
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    cursor: pointer;
    font-size: 2vh;
    color: white;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2; /* Ensure it's above other elements */
}

.scroll-arrow:hover .arrow {
    opacity: 0;
}

.scroll-arrow:hover .scroll-text {
    opacity: 1;
}

.arrow {
    display: inline-block;
    width: 3vh; /* Adjust width for the wide V shape */
    height: 3vh; /* Adjust height for the wide V shape */
    border-left: 4px solid white; /* Adjust border thickness for the wide V shape */
    border-bottom: 4px solid white; /* Adjust border thickness for the wide V shape */
    transform: rotate(-45deg);
    transition: opacity 0.3s ease-in-out;
}

.scroll-text {
    display: block;
    opacity: 0;
    font-size: 1.5vh;
    color: white;
    transition: opacity 0.3s ease-in-out;
    margin-top: -1vh;
}

.join-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    text-align: center;
    flex: 1;
    padding: 5vh 0; /* Add padding to the top and bottom */
}

.recruitment-season,
.questions-comments {
    padding: 10vh 5vh 5vh 5vh; /* Add padding to the top and bottom */
    text-align: center;
    background-color: #f0f0f0; /* Light background color */
    color: #333; /* Dark text color */
    margin: 0 auto; /* Center the section horizontally */
    margin-top: -5vh;
    max-width: 160vh; /* Set a maximum width */
    z-index: 1;
}
.recruitment-season h2,
.questions-comments h2 {
    font-size: 5vh;
    margin-bottom: 4vh;
    color: #b71818; /* Red color for the header */
}

.recruitment-season p,
.questions-comments p {
    font-size: 2.5vh;
    line-height: 1.6;
    margin-bottom: 7vh; /* Space between the text and the statistics */
}

.recruitment-season h1 {
    font-size: 5vh;
    margin-top: -4vh;
    color: #b71818; /* Red color for the header */
}

.recruitment-button {
    display: inline-block;
    margin: 4vh; /* Increase the top margin to move the button lower */
    padding: 1.5vh 3vh; /* Adjust padding for button */
    font-size: 3vh; /* Adjust font size */
    color: #333; /* Button text color */
    background-color: white; /* Button background color */
    border: 0.2vh solid #333; /* Red outline */
    border-radius: 0.5vh; /* Optional: Add border radius */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for hover effect */
    text-decoration: none;
}

.recruitment-button:hover {
    background-color: #b71818; /* Red background on hover */
    color: white; /* White text on hover */
}

.events-image {
    max-width: 90%;
    margin-top: 5vh;
}

.event-box-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    grid-row-gap: 10vh; /* Adjust the gap between rows */
    grid-column-gap: 10vh; /* Adjust the gap between columns */
    justify-content: center;
    margin: -90vh auto 0 auto; /* Adjust the negative margin-top as needed */
    position: relative; /* Position relative to allow overlap with the image */
    z-index: 2; /* Ensure the grid appears above the image */
}
/* Non-required events */
.general {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 5vh 5vh;
    justify-content: center;
    margin: -88vh auto 0 auto;
}

.general .event-box {
    width: 40vh;
    height: 33vh;
}

/* Required events */
.invite {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four equal columns */
    gap: 3vh 6vh;
    justify-content: center;
    margin: 1vh auto;
}

.invite .event-box {
    width: 28vh; /* Slightly narrower */
    height: 33vh;
}

.event-box {
    width: 33vh; /* Set a fixed width */
    height: 33vh; /* Set the same height to make it square */
    padding: 2vh; /* Adjust padding as needed */
    background-color: rgba(245, 240, 240, 0.7); /* Reduced opacity background color */
    color: #333; /* Default text color */
    box-shadow: 0 0 2vh rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 0.5vh;
    margin-bottom: 15vh;
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    text-decoration: none; /* Remove underline */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
}

.event-box h3{
    font-size: 2.7vh;
}
.event-box p {
    margin: 0; /* Remove any default margin to keep layout consistent */
    transition: color 0.3s ease; /* Smooth transition for text color on hover */
}

.event-box a {
    text-decoration: none; /* Ensure no underline on the links */
    color: inherit; /* Keep the text color as inherited */
}

.event-box:hover {
    transform: scale(1.05); /* Scale up the box by 5% on hover */
    background-color: #b71818; /* Change background color on hover */
    color: #fff; /* Change the overall text color on hover */
}

.asterisk {
    margin-top: -5vh; /* Increase margin to push it down */
    margin-bottom: 10vh;
    text-align: center;
    z-index: 2;
}

.event-box:hover h3 {
    color: #ffffff; /* Change the h3 text color on hover */
}

.event-box:hover p {
    color: #dddddd; /* Change the p text color on hover */
}

.join-section {
    position: relative; /* Ensure the background image stays in place */
    z-index: 1; /* Ensure the image is behind the grid */
    /* Other properties you have for join-section */
}

.director-image {
    max-width: 200px;
    height: auto;
    margin: 2rem auto;
    display: block;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.director-image:hover {
    transform: scale(1.1);
}

.director-name {
    font-size: 2.2vh;
    color: #333;
    text-align: center;
    margin-top: 1rem;
    font-weight: bold;
}

.director-email {
    font-size: 1.7vh; /* Use vh for responsiveness */
    color: #777; /* Gray color for the subtitle */
    white-space: normal; /* Allow wrapping */
    overflow: hidden;
    line-height: 1.5em; /* Align vertically */
    text-align: center; /* Center text */
    display: block;
    /*height: 4vh; /* Set a fixed height for the subtitle */
    margin-top: -7vh;
}

.recruitment-button {
    display: inline-block;
    margin: 4vh; /* Increase the top margin to move the button lower */
    padding: 1.5vh 3vh; /* Adjust padding for button */
    font-size: 3vh; /* Adjust font size */
    color: #333; /* Button text color */
    background-color: white; /* Button background color */
    border: 0.2vh solid #333; /* Red outline */
    border-radius: 0.5vh; /* Optional: Add border radius */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for hover effect */
    text-decoration: none;
  }
  
  .recruitment-button:hover {
    background-color: rgb(191, 27, 0); /* Red background on hover */
    color: white; /* White text on hover */
    border: 0.2vh solid white; /* Red outline */
  }

  /* Mobile screens (up to 430px, e.g., iPhone 14 Pro Max) */
@media only screen and (max-width: 430px) {
    /* First Section Styling for iPhone View */
    .join-section {
      position: relative;
      width: 100%;
      height: 100vh; /* Ensure it covers the full height of the viewport */
      overflow: hidden;
      margin: 0;
      padding: 0;
      z-index: 1; /* Ensure it's above the background */
      margin-top: -9.5vh; /* Adjust to match the video section styling */
      padding-top: 10vh; /* Add padding for alignment */
      background-attachment: scroll;
    }
  
    .join-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(151, 148, 148, 0.4); /* Dark overlay with opacity */
      z-index: 1;
    }
  
    .join-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; /* Ensure the text is above the overlay */
      padding: 2rem;
      border-radius: 1rem;
      text-align: center; /* Center align text */
      color: whitesmoke; /* Text color */
    }
  
    .join-text h1 {
      font-size: 4vh;
      margin-bottom: 2vh;
    }
  
    .join-text p {
      font-size: 2vh;
      margin-bottom: 2vh;
    }

    .recruitment-button {
        display: inline-block;
        margin: 4vh auto; /* Center the button and add vertical space */
        padding: 4vh 10vh; /* Increase padding for a larger button */
        font-size: 4vh; /* Increase font size */
    }

    .recruitment-button:hover {
        background-color: #ffffff; /* White background on hover */
        color: #b71818; /* Red text on hover */
        border: 0.3vh solid #b71818; /* Red outline remains */
    }

    .events-image {
        max-width: 100%;
        height: auto;
        margin-top: 2vh; /* Adjust margin to fit the layout */
      }
  
    /* Recruitment Date Boxes Styling for iPhone View */
    .event-box-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Two columns */
      grid-row-gap: 0vh; /* Adjust the gap between rows */
      grid-column-gap: 3vh; /* Adjust the gap between columns */
      justify-content: center;
      margin: 0 auto; /* Adjust margin */
      position: relative;
      z-index: 2;
      margin-top: -27vh;
    }
  
    .event-box {
      width: 12vh; /* Smaller width for the event boxes */
      height: 12vh; /* Smaller height to make it square */
      padding: 0vh; /* Adjust padding as needed */
      background-color: rgba(245, 240, 240, 0.7); /* Reduced opacity background color */
      color: #333; /* Default text color */
      box-shadow: 0 0 2vh rgba(0, 0, 0, 0.1);
      text-align: center;
      border-radius: 0.5vh;
      margin-bottom: 2vh;
      transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center the content vertically */
    }
  
    .event-box h3 {
      font-size: 1.5vh; /* Adjust font size */
      margin: 0; /* Remove default margin */
      transition: color 0.3s ease; /* Smooth transition for text color on hover */
    }
  
    .event-box p {
      font-size: 1.1vh; /* Adjust font size */
      margin: 0; /* Remove default margin */
      transition: color 0.3s ease; /* Smooth transition for text color on hover */
    }
  
    .event-box:hover {
      transform: scale(1.05); /* Scale up the box by 5% on hover */
      background-color: #b71818; /* Change background color on hover */
      color: #fff; /* Change the overall text color on hover */
    }
  
    .event-box:hover h3,
    .event-box:hover p {
      color: #ffffff; /* Change text color on hover */
    }
  }
  