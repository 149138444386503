/* Ensure the video covers the entire viewport */
.video-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensure it covers the full height of the viewport */
  overflow: hidden;
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  z-index: 1; /* Ensure video is behind the navbar */
  margin-top: -12.5vh; /* Adjust this value based on the height of your navbar */
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  object-fit: cover; /* Ensure the video covers the entire screen */
  transform: translate(-50%, -50%);
  display: block; /* Ensure no extra space around the video */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  z-index: -1; /* Ensure video is behind other elements */
}

.scroll-arrow {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  cursor: pointer;
  font-size: 2vh;
  color: white;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2; /* Ensure it's above other elements */
}

.scroll-arrow:hover .arrow {
  opacity: 0;
}

.scroll-arrow:hover .scroll-text {
  opacity: 1;
}

.arrow {
  display: inline-block;
  width: 3vh; /* Adjust width for the wide V shape */
  height: 3vh; /* Adjust height for the wide V shape */
  border-left: 4px solid white; /* Adjust border thickness for the wide V shape */
  border-bottom: 4px solid white; /* Adjust border thickness for the wide V shape */
  transform: rotate(-45deg);
  transition: opacity 0.3s ease-in-out;
}

.scroll-text {
  display: block;
  opacity: 0;
  font-size: 1.5vh;
  color: white;
  transition: opacity 0.3s ease-in-out;
  margin-top: -1vh;
}


/* Navbar styles */

.nav {
  display: flex;
  align-items: center;
  position: relative; /* Use relative positioning */
  padding: 1.5vh 3vh; /* Adjust padding as needed */
  background-color: transparent;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other elements */
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0; /* Remove any margin */
}

.logo {
  margin-left: 2.5vh;
  height: 12vh; /* Adjust the height */
  width: auto;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 4vh; /* Adjust the gap */
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline;
}

.nav-links li a {
  text-decoration: none;
  color: whitesmoke;
  font-size: 1.6vh; /* Adjust font size */
  position: relative;
  padding: 1vh; /* Adjust padding */
}

.nav-links li a:hover {
  color: rgb(191, 27, 0);
}

.nav-links li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0.2vh;
  display: block;
  margin-top: 0.5vh;
  right: 0;
  background: rgb(191, 27, 0);
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}

.nav-links li a:hover::after {
  width: 100%;
  left: 0;
  background: rgb(191, 27, 0);
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

/* Rest of your styles */

.recruitment-button {
  display: inline-block;
  margin: 4vh auto 0; /* Increase the top margin to move the button lower */
  padding: 1.5vh 3vh; /* Adjust padding for button */
  font-size: 2vh; /* Adjust font size */
  color: grey; /* Button text color */
  background-color: white; /* Button background color */
  border: 0.2vh solid grey; /* Red outline */
  border-radius: 0.5vh; /* Optional: Add border radius */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition for hover effect */
  text-decoration: none;
}

.recruitment-button:hover {
  background-color: rgb(191, 27, 0); /* Red background on hover */
  color: white; /* White text on hover */
  border: 0.2vh solid white; /* Red outline */
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensure content is above the video */
  color: white; /* Adjust as needed */
  text-align: center;
}

.text-content {
  position: relative;
  top: -5vh; /* Adjust this value to move the text higher */
}

.content h2 {
  margin-bottom: 3vh;
  font-size: 4vh; /* Maintain font size for title */
  font-weight: bold; /* Make it bold */
  font-family: 'avenir-lt-w01_85-heavy1475544', 'avenir-lt-w05_85-heavy', sans-serif; /* Updated font family */
  white-space: nowrap;
  color: whitesmoke;
}

.container {
  display: inline-block;
}

/* Add these styles for the typewriter effect */
.typewriter {
  font-size: 2vh;
  border-right: 0.5vh solid;
  color: whitesmoke;
  white-space: nowrap;
  overflow: hidden;
  animation:
      typing 5s steps(35),
      cursor .4s step-end infinite alternate;
  margin-bottom: 3vh;
  width: fit-content; /* Set the width to fit the content */
  margin: 0 auto; /* Center the typewriter text container */
  position: relative; /* Position relative for absolute child */
}

.typewriter::after {
  content: 'Developing The Leaders Of Tomorrow.';
  visibility: hidden;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes cursor {
  50% { border-color: transparent; }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Reset default margins and paddings for body and html */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
  font-family: "Source Sans Pro", sans-serif;
}

.home-section {
  background-color: #f0f0f0; /* Set background color */
  padding: 0; /* Remove padding to allow full coverage */
  text-align: center; /* Center align the text */
  position: relative; /* Position relative for full-screen adjustment */
  width: 100%; /* Ensure full width */
  height: 100vh; /* Ensure it fills the entire viewport height */
  box-sizing: border-box; /* Include padding in width */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  flex-direction: column; /* Stack image and text vertically */
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vh; /* Adjust gap between image and text */
  flex-wrap: nowrap; /* Prevent wrapping for full-screen layout */
  height: 80%; /* Adjust height to fit within the viewport */
}

.home-image {
  max-width: 30%; /* Adjust the image width */
  margin-right: 10vh;
  height: auto; /* Maintain aspect ratio */
  border-radius: 1vh; /* Optional: Add border radius to the image */
  opacity: 0; /* Start with the image hidden */
  transition: opacity 1s ease-in-out; /* Smooth fade-in effect */
}

.home-image.fade-in {
  opacity: 1; /* Fade in the image */
}

.home-text {
  max-width: 50%; /* Adjust max width for better text display */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  text-align: left; /* Left-align the text */
  opacity: 0; /* Start with the text hidden */
  transition: opacity 1s ease-in-out 0.5s; /* Smooth fade-in effect with slight delay */
  font-size: 2.5vh; /* Adjust text size */
}

.home-text.fade-in {
  opacity: 1; /* Fade in the text */
}

.home-text h2 {
  font-size: 4vh; /* Adjust font size for the heading */
  color: #b71818; /* Adjust text color */
  margin-bottom: 4vh; /* Adjust margin below the heading */
  font-family: 'avenir-lt-w01_85-heavy1475544', 'avenir-lt-w05_85-heavy', sans-serif; /* Match the font style */
}

.home-text p {
  font-size: 2vh; /* Adjust font size */
  margin-bottom: 2vh; /* Add margin below each paragraph */
  line-height: 1.5; /* Improve readability with line height */
}

.our-pillars-section {
  background-image: url('/src/images/home/home-image2.jpg'); /* Add your background image */
  background-size: cover;
  background-attachment: fixed; /* Fix the background for parallax effect */
  background-position: center center;
  height: 100vh; /* Full screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: whitesmoke; /* Light text color */
  padding: 0 2vh; /* Padding for smaller screens */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.our-pillars-content {
  max-width: 80%;
  transition: transform 0.2s ease-out; /* Smooth transition for parallax effect */
}

.our-pillars-section h2 {
  color: whitesmoke;
  font-size: 5vh; /* Font size for the section title */
  margin-bottom: 2vh; /* Margin below the title */
}

.our-pillars-section p {
  font-size: 2.5vh; /* Font size for the paragraph */
  margin-bottom: 2vh; /* Margin below the paragraph */
}

.pillars-section {
  background-color: #f0f0f0; /* Light grey background */
  padding: 5vh 2vh; /* Padding around the section */
  text-align: center; /* Center-align text */
  color: white; /* Dark text color */
}

.pillars-section h2 {
  font-size: 3vh; /* Reduce font size for the section title */
  color: white; /* Dark color for the title */
  margin-bottom: 2vh; /* Reduce margin below the title */
}

.pillars-content {
  display: flex;
  flex-direction: column;
  gap: 2vh; /* Reduce gap between pillars */
}

.pillar {
  opacity: 0; /* Ensure initial state is hidden */
  transition: opacity 0.5s ease-out; /* Smooth transition */
  display: flex;
  align-items: center;
  margin-bottom: 2vh; /* Reduce margin between pillars */
  width: 90%; /* Adjust width to control overall spacing */
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}

.pillar-left {
  justify-content: flex-start; /* Align content to the start (left) */
}

.pillar-right {
  justify-content: flex-start; /* Align content to the start (left) */
  flex-direction: row-reverse; /* Ensure image is on the right */
}

.pillar-image {
  width: 35%; /* Adjust width of the images */
  height: auto;
  margin: 0 1vh; /* Adjust margin to balance image position */
}

.pillar-text {
  width: auto; /* Adjust width of the text container */
  margin-left: 8vh; /* Add space between image and text for left-aligned pillars */
  margin-right: 8vh; /* Add space between image and text for right-aligned pillars */
}

.pillar-text h3 {
  font-size: 4vh; /* Reduce font size for the pillar titles */
  color: #b71818; /* Dark color for the titles */
  margin: 0; /* Remove margin below the titles */
}

.separator {
  height: .2vh; /* Use vh for height */
  background-color: #f0f0f0; /* Light gray color for the separator */
  margin: 0 auto; /* Center the separator */
  padding: .2vh;
  width: 50%;
  transform: translateX(50%);
}

.companies-section {
  background-color: #f0f0f0;
  padding: 5vh 2vh;
  text-align: center;
}

.companies-section h2 {
  margin-top: 10vh;
  font-size: 4vh;
  color: #b71818;
  margin-bottom: 8vh;
}

.logos-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1vh; /* Reduced gap between logos */
  justify-items: center;
  margin-bottom: 10vh;
}

.company-logo {
  opacity: 0; /* Ensure initial state is hidden */
  transition: opacity 0.5s ease-out; /* Smooth transition */
  width: 150px; /* Set fixed width for all logos */
  height: 100px; /* Set fixed height for all logos */
  object-fit: contain; /* Ensure the logos fit within the specified dimensions */
  margin: 0.5vh; /* Reduced margin */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

@keyframes cursor {
  50% { border-color: transparent; }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Rest of your styles */

.footer {
  background-color: #171717; /* Same black color as the pillar section */
  padding: 2vh 0;
  text-align: center;
  color: whitesmoke;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2vh;
}

.social-link {
  color: whitesmoke;
  font-size: 2rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-link:hover {
  transform: scale(1.2);
  color: #1da1f2; /* Change color on hover */
}

.footerBottom {
  margin-top: 20px;
  font-size: 1.7vh;
}

/* Tablet and smaller screens (up to 768px) */
@media only screen and (max-width: 768px) {
  .video-section {
    margin-top: -8vh;
  }


  .scroll-arrow {
    font-size: 1.5vh;
  }

  .nav {
    padding: 1vh 2vh;
  }

  .logo {
    height: 10vh;
    margin-left: 2vh;
  }

  .nav-links li a {
    font-size: 1.4vh;
  }

  .recruitment-button {
    font-size: 1.6vh;
    padding: 1.2vh 2.5vh;
  }

  .typewriter {
    font-size: .5vh; /* Adjust the font size to fit the text on one line */
    max-width: 100%; /* Allow the text to fit within the full screen width */
    width: 100%; /* Ensure the width is 100% */
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflow to keep it clean */
    text-align: center; /* Center the text horizontally */
  }

  .content h2 {
    font-size: 2.5vh;
    max-width: 90%;
    word-wrap: break-word; /* Ensure long words break */
    white-space: normal; /* Allow text to wrap to the next line */
    line-height: 1.2; /* Adjust line-height for readability */
    text-align: center; /* Center-align the text */
  }

  .text-content {
    top: -4vh;
  }

  .typewriter {
    font-size: 1.6vh;
    max-width: 90%; /* Ensure the text fits within the screen */
  }

  .home-content {
    flex-direction: column;
    gap: 3vh;
    height: auto;
    padding: 2vh; /* Add padding to account for narrower width */
  }

  .home-image {
    max-width: 60%;
    margin-right: 0;
  }

  .home-text {
    max-width: 80%;
    font-size: 2vh;
  }

  .home-text h2 {
    font-size: 3vh;
    margin-bottom: 3vh;
    max-width: 100%;
  }

  .home-text p {
    font-size: 1.8vh;
    margin-bottom: 1.5vh;
  }

  .our-pillars-section {
    height: auto; /* Adjust to content */
    padding: 3vh 2vh;
  }

  .our-pillars-section h2 {
    font-size: 4vh;
    max-width: 90%;
    word-wrap: break-word;
  }

  .our-pillars-section p {
    font-size: 2vh;
    max-width: 90%;
    word-wrap: break-word;
  }

  .pillars-section {
    padding: 3vh 2vh;
  }

  .pillars-section h2 {
    font-size: 2.5vh;
  }

  .pillar-image {
    width: 50%;
  }

  .pillar-text h3 {
    font-size: 3.5vh;
  }

  .companies-section h2 {
    font-size: 3.5vh;
  }

  .logos-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footerBottom {
    font-size: 1.5vh;
  }
}

/* Mobile screens (up to 430px, e.g., iPhone 14 Pro Max) */
@media only screen and (max-width: 430px) {
  .video-section {
    height: 100vh; /* Ensure it covers the full height of the viewport */
    margin-top: -9.5vh; /* Keep margin-top as required */
    padding-top: 10vh; /* Add padding equal to the margin-top to align content properly */
  }

  .content {
    top: 50%; /* Center content vertically */
    transform: translate(-50%, -50%);
    padding: 0 2vh; /* Add padding to ensure the content is not too close to the edges */
  }

  .scroll-arrow {
    font-size: 1.2vh; /* Smaller arrow */
    bottom: 3vh; /* Adjust position to stay above the next section */
  }

  .home-section {
    height: 100vh; /* Ensure it covers the full height of the viewport */
    padding-top: 5vh; /* Adjust padding to center content properly */
    padding-bottom: 5vh; /* Ensure bottom padding for spacing */
  }

  .recruitment-button {
    font-size: 1.2vh !important;
    padding: 0.8vh 1.5vh !important;
    width: auto !important;
    display: inline-block !important;
    margin-top: 2vh !important;
  }

  .home-content {
    flex-direction: column;
    gap: 1vh; /* Reduce the gap between elements */
    height: 100%; /* Adjust the height to ensure everything fits */
    padding: 0 2vh; /* Add padding to the sides */
    justify-content: center; /* Center content vertically */
  }

  .home-text h2 {
    font-size: 2.0vh; /* Reduce heading size */
    margin-bottom: 2vh; /* Reduce margin */
    text-align: center; /* Center-align text */
  }

  .home-text p {
    font-size: 1.3vh; /* Reduce paragraph size */
    margin-bottom: 1vh; /* Reduce margin */
    line-height: 1.3; /* Adjust line height for readability */
    text-align: center; /* Center-align text */
  }

  .home-image {
    max-width: 70%; /* Ensure the image fits within the screen */
    margin-bottom: 2vh; /* Add margin below the image */
  }

  .our-pillars-section {
    height: auto; /* Allow the height to adjust based on content */
    padding: 20vh 4vh; /* Adjust padding to ensure content fits well */
    text-align: center; /* Center-align text */
    background-size: cover; /* Ensure background image covers the section */
    background-attachment: scroll; /* Adjust background to scroll with content */
  }

  .our-pillars-content {
    max-width: 90%; /* Ensure content doesn't stretch too wide */
    margin: 0 auto; /* Center the content horizontally */
  }

  .our-pillars-section h2 {
    font-size: 3vh; /* Adjust font size for the title */
    margin-bottom: 3vh; /* Add some margin below the title */
    text-align: center; /* Center-align the title */
  }

  .our-pillars-section p {
    font-size: 1.6vh; /* Adjust font size for the paragraphs */
    margin-bottom: 2vh; /* Add some margin below paragraphs */
    line-height: 1.4; /* Adjust line height for readability */
  }

  .pillars-content {
    display: flex;
    flex-direction: column;
    gap: 1vh; /* Space between each pillar */
  }

  .pillar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; /* Keep image and text side by side */
    padding: 0vh 0; /* Add vertical padding for spacing */
    width: 100%; /* Ensure all pillars take up the full width */
    margin-bottom: 0vh;
  }

  .pillar-left, .pillar-right {
    justify-content: space-between; /* Ensure content is spread out */
    width: 100%; /* Ensure all pillars take up the full width */
  }

  .pillar-left {
    flex-direction: row; /* Ensure image is on the left, text on the right */
  }

  .pillar-right {
    flex-direction: row-reverse; /* Ensure image is on the right, text on the left */
  }

  .pillar-image {
    width: 45%; /* Adjust the width of the images */
    height: auto;
    margin: 0 .2vh; /* Space between the image and text */
  }

  .pillar-text {
    width: 65%; /* Make the text container take up the remaining space */
    font-size: 2.3vh; /* Adjust the font size for better readability */
    margin-bottom: 0vh; /* Remove unnecessary space below the text */
    color: #b71818; /* Keep the text color consistent */
  }

  .pillar-text h3 {
    font-size: 2.5vh; /* Adjust the size of the pillar titles */
    margin-bottom: 0vh; /* Remove unnecessary space below the titles */
  }

  .logos-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(10, auto); /* 10 rows */
    gap: 2vh; /* Adjust the gap between logos */
    justify-items: center; /* Center align items within each grid cell */
  }

  .company-logo {
    width: 100px; /* Adjust width of each logo */
    height: 60px; /* Adjust height of each logo */
    object-fit: contain; /* Ensure logos fit within the specified dimensions */
    margin: 0.5vh; /* Adjust margin around logos */
  }
}
